import { Controller } from "@hotwired/stimulus";
import { debounce } from "../application/debounce";
import * as Rails from '@rails/ujs';

export class CartLineController extends Controller {
  declare valueTarget: HTMLInputElement;
  declare formTarget: HTMLFormElement;

  private updateLine = debounce(() => this.submit(), 400);

  static targets = ["value", "form"];

  public increment() {
    this.quantity++;
  }

  public decrement() {
    this.quantity--;
  }

  public keydown(e: KeyboardEvent) {
    if (e.key === "Enter") {
      this.valueTarget.blur();
      e.preventDefault();
    }
  }

  public submit() {
    Rails.fire(this.formTarget, 'submit');
  }

  private get quantity(): number {
    return Number.parseInt(this.valueTarget.value, 10) || 1;
  }

  private set quantity(newQuantity: number) {
    this.valueTarget.value = Math.max(newQuantity, 1).toString();
    this.updateLine();
  }
}