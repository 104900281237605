import { Controller } from "@hotwired/stimulus";

export class PasswordInputController extends Controller {
  declare inputTarget: HTMLInputElement;
  static targets = ["input"];

  public toggle(e: Event) {
    const target = e.currentTarget as HTMLElement;

    if (this.inputTarget.type === 'password') {
      target.textContent = 'Hide';
      this.inputTarget.type = 'text';
    } else {
      target.textContent = 'Show';
      this.inputTarget.type = 'password';
    }
  }
}
