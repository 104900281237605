import { StartQuoteCarousel } from "./carousels/quote-carousel"
import { StartProductCarousel } from "./carousels/product-carousel"
import * as React from "react";
import { render } from "react-dom";
import { MiniCart } from "./mini-cart"
import { Application } from "@hotwired/stimulus";

import { DropdownController } from "../controllers/dropdown";
import { ProductSearchController } from "../controllers/product-search";
import { AddToCartController } from "../controllers/add-to-cart";
import { CheckoutFormController } from "../controllers/checkout-form";
import { FilterDrawerController } from "../controllers/filter-drawer";
import { CartLineController } from "../controllers/cart-line";
import { ClubInterstitialController } from "../controllers/club-interstitial";
import { ProductGalleryController } from "../controllers/product-gallery";
import { CharacterCountController } from "../controllers/character-count";
import { checkAge } from "./age-gate";
import { showCookieBanner } from "./cookies";
import { scrollEvents } from "./scroll-events";
import { MiniNavController } from "../controllers/mini-nav";
import { ToggleVisibleController } from "../controllers/toggle-visible";
import { StripeFormController } from "../controllers/stripe-form";
import { PasswordInputController } from "../controllers/password-input";
import { LoadMoreController } from "../controllers/load-more";
import { CarouselController } from "../controllers/carousel";
import { ProductRatingController } from "../controllers/product-rating";
import { AccordionController } from "../controllers/accordion";
import { SelectionUpdateController } from "../controllers/selection-update";
import { DateInputController } from "../controllers/date-input";
import { ClickToCopyController } from "../controllers/click-to-copy";

import "../channels";

const application = Application.start();

application.register("dropdown", DropdownController);
application.register("password-input", PasswordInputController);
application.register("add-to-cart", AddToCartController);
application.register("checkout-form", CheckoutFormController);
application.register("toggle-visible", ToggleVisibleController);
application.register("product-search", ProductSearchController);
application.register("filter-drawer", FilterDrawerController);
application.register("cart-line", CartLineController);
application.register("club-interstitial", ClubInterstitialController);
application.register("load-more", LoadMoreController);
application.register("product-gallery", ProductGalleryController);
application.register("mini-nav", MiniNavController);
application.register("character-count", CharacterCountController);
application.register("stripe-form", StripeFormController);
application.register("carousel", CarouselController);
application.register("product-rating", ProductRatingController);
application.register("accordion", AccordionController);
application.register("selection-update", SelectionUpdateController);
application.register("date-input", DateInputController);
application.register("click-to-copy", ClickToCopyController);

document.addEventListener('turbo:load', () => {
  StartQuoteCarousel();
  StartProductCarousel();
  scrollEvents();
  checkAge();
  showCookieBanner();

  const element = document.querySelector<HTMLElement>(".mini-cart-container");
  if (element) {
    const zip = element.dataset['zip'];
    let quantity = element.dataset['quantity'];

    if (quantity === '0') {
      quantity = undefined;
    }

    render(<MiniCart zipCode={zip} quantity={quantity} />, element);
  }
})







