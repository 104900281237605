import { Controller } from "@hotwired/stimulus";

export class ToggleVisibleController extends Controller {
  public toggle(e: MouseEvent) {
    e.preventDefault();
    const target = e.currentTarget as HTMLInputElement;

    if (target.checked) {
      this.element.classList.remove('is-hidden');
    } else {
      this.element.classList.add('is-hidden');
    }
  }
}