import { Controller } from "@hotwired/stimulus";
import { titlecase } from "../helpers";

enum ClubType {
  Red,
  White,
  Mixed,
  Collectors
}

enum Frequency {
  Monthly = 1,
  Bimonthly = 2,
  Quarterly = 3
}

export class ClubInterstitialController extends Controller {
  declare clubTarget: HTMLElement;
  declare quantityTarget: HTMLElement;
  declare frequencyTarget: HTMLElement;
  declare threeBottlePriceTarget: HTMLElement;
  declare threeBottleDescriptionTarget: HTMLElement;
  declare sixBottlePriceTarget: HTMLElement;
  declare sixBottleDescriptionTarget: HTMLElement;
  declare twelveBottlePriceTarget: HTMLElement;
  declare twelveBottleDescriptionTarget: HTMLElement;
  declare totalTarget: HTMLElement;
  declare actionsTarget: HTMLFormElement;
  static targets = ["club", "quantity", "frequency", "threeBottlePrice", "threeBottleDescription",
    "sixBottlePrice", "sixBottleDescription",
    "twelveBottlePrice", "twelveBottleDescription",
    "total", "actions"];

  public connect() {
    this.updateForm();
  }

  public updateForm() {
    this.updateTitles();
    this.updateQuantity();
    this.updateVisibility();

    if (this.clubId) {
      this.actionsTarget.action = `/wine-club/subscribe/${this.clubId}/zip`;
    }
  }

  private updateQuantity() {
    // Update the descriptions based on the selected club
    this.threeBottleDescriptionTarget.innerHTML = this.description(3);
    this.threeBottlePriceTarget.innerText = `$${this.threeBottlePrice} / shipment`;

    this.sixBottleDescriptionTarget.innerHTML = this.description(6);
    this.sixBottlePriceTarget.innerText = `$${this.sixBottlePrice} / shipment`;

    this.twelveBottleDescriptionTarget.innerHTML = this.description(12);
    this.twelveBottlePriceTarget.innerText = `$${this.twelveBottlePrice} / shipment`;
  }

  private updateVisibility() {
    if (this.club) {
      this.show(this.quantityTarget);
    } else {
      this.hide(this.quantityTarget);
    }

    if (this.quantity) {
      this.show(this.frequencyTarget);
    } else {
      this.hide(this.frequencyTarget);
    }

    if (this.frequency) {
      this.show(this.actionsTarget);
    } else {
      this.hide(this.actionsTarget);
    }
  }

  private hide(el: HTMLElement) { el.style.display = "none"; }
  private show(el: HTMLElement) { el.style.display = "inherit"; }

  private updateTitles() {
    if (this.club) {
      const title = this.clubTarget.querySelector<HTMLElement>(".title")!
      title.innerHTML = `1. Great! You've selected the <u>${titlecase(this.club)} Club</u>`;
    }

    if (this.quantity) {
      const title = this.quantityTarget.querySelector<HTMLElement>(".title")!
      title.innerHTML = `2. Each shipment will include <u>${this.quantity} Bottles of Wine`;
      switch (this.quantity) {
        case '3':
          this.totalTarget.innerText = this.threeBottlePrice!;
          break;
        case '6':
          this.totalTarget.innerText = this.sixBottlePrice!;
          break;
        case '12':
          this.totalTarget.innerText = this.twelveBottlePrice!;
          break;
      }
    }

    if (this.frequency) {
      const title = this.frequencyTarget.querySelector<HTMLElement>(".title")!
      title.innerHTML = `3. You'll receive a shipment <u>${titlecase(this.frequency)}</u>`;
    }
  }

  /**
   * Get the description for the club based on bottle count and club chosen
   */
  private description(quantity: 3 | 6 | 12) {
    switch (this.clubType) {
      case ClubType.Red: return `${quantity} x red wine`;
      case ClubType.White: return `${quantity} x white wine`;
      case ClubType.Mixed:
        return `${quantity / 3} x white wine<br>${2 * quantity / 3} x red wine`;
      default: return '';
    }
  }

  /**
   * Get the club name
   */
  private get club() {
    return this.clubTarget.querySelector<HTMLInputElement>("input:checked")?.value;
  }

  /**
   * Get the quantity of bottles
   */
  private get quantity() {
    return this.quantityTarget.querySelector<HTMLInputElement>("input:checked")?.value;
  }

  /**
   * Get the frequency
   */
  private get frequency() {
    return this.frequencyTarget.querySelector<HTMLInputElement>("input:checked")?.value;
  }

  private get frequencyInMonths() {
    switch (this.frequencyTarget.querySelector<HTMLInputElement>("input:checked")?.dataset['months']) {
      case '1': return Frequency.Monthly;
      case '2': return Frequency.Bimonthly;
      case '3': return Frequency.Quarterly;
    }

    return null;
  }

  private get threeBottlePrice(): string | undefined {
    return this.clubTarget.querySelector<HTMLInputElement>("input:checked")?.dataset['threeBottlePrice'];
  }

  private get sixBottlePrice(): string | undefined {
    return this.clubTarget.querySelector<HTMLInputElement>("input:checked")?.dataset['sixBottlePrice'];
  }

  private get twelveBottlePrice(): string | undefined {
    return this.clubTarget.querySelector<HTMLInputElement>("input:checked")?.dataset['twelveBottlePrice'];
  }

  private get clubType(): ClubType | undefined {
    const clubType = this.club?.toLowerCase() || '';

    if (clubType.includes("red")) { return ClubType.Red; }
    if (clubType.includes("white")) { return ClubType.White; }
    if (clubType.includes("mixed")) { return ClubType.Mixed; }
    if (clubType.includes("collectors")) { return ClubType.Collectors; }

    return undefined;
  }

  private get clubId(): string | undefined {
    const selectedClub = this.clubTarget.querySelector<HTMLInputElement>("input:checked");
    if (!selectedClub) { return undefined; }
    if (!this.frequencyInMonths) { return undefined; }
    if (!this.quantity) { return undefined; }

    return selectedClub.dataset[`${this.quantity}b-${this.frequencyInMonths}m`];
  }
}