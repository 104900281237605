import { Controller } from "@hotwired/stimulus";

export class DateInputController extends Controller {
  changeCallback = (e: InputEvent) => this.change(e);

  connect() {
    this.element.addEventListener("input", this.changeCallback);
  }

  disconnect() {
    this.element.removeEventListener("input", this.changeCallback);
  }

  private change(e: InputEvent) {
    const element = e.currentTarget as HTMLInputElement;
    let input = element.value;

    // Pressed backspace in front of a slash
    if (/\D\/$/.test(input) && e.inputType !== "insertText") {
      input = input.substr(0, input.length - 3);
    }

    const values = input.split('/').map(v => v.replace(/\D/g, ''));

    if (values[0]) values[0] = this.checkValue(values[0], 12);
    if (values[1]) values[1] = this.checkValue(values[1], 31);

    const output = values.map((v, i) => v.length == 2 && i < 2 ? v + ' / ' : v);
    element.value = output.join('').substr(0, 14);
  }

  private checkValue(str: string, max: number): string {
    if (str.charAt(0) !== '0' || str == '00') {
      let num = parseInt(str);
      if (isNaN(num) || num <= 0 || num > max) {
        num = 0;
      }

      str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
    };

    return str;
  };
}