import { Controller } from "@hotwired/stimulus";
import * as Rails from '@rails/ujs';
import { debounce } from "../application/debounce";

export class ProductSearchController extends Controller {
  declare inputTarget: HTMLInputElement;
  declare clearButtonTarget: HTMLButtonElement;
  declare formTarget: HTMLFormElement;
  declare displayTarget: HTMLDivElement;
  static targets = ["form", "input", "clearButton", "display"];

  private search = debounce(() => {
    Rails.fire(this.formTarget, 'submit')
  }, 200);

  public toggle() {
    this.element.classList.toggle("m-search--active");

    if (this.element.classList.contains("m-search--active")) {
      this.inputTarget.focus();
    }
  }

  public onchange(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      this.clear();
      this.element.classList.remove("m-search--active");
    }

    this.toggleClearButton();
    this.search();
  }

  public clear() {
    this.inputTarget.value = "";
    this.inputTarget.focus();
    this.toggleClearButton();
    Rails.fire(this.formTarget, 'submit');
  }

  /**
   * Toggle the clear button based on the value of the target
   * 
   * Only shows when the input has some text in it
   */
  private toggleClearButton() {
    const visible = !!this.inputTarget.value;
    if (visible) {
      this.clearButtonTarget.classList.add("is-visible");
    } else {
      this.clearButtonTarget.classList.remove("is-visible");
    }
  }
}