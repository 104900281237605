import Swiper from "swiper";

export function StartProductCarousel() {
  new Swiper('.js-swiper-product-carousel-container', {
    slidesPerView: 'auto',
    threshold: 10,
    navigation: {
      nextEl: '.js-swiper-product-carousel-container .js-carousel-indicator__arrow--next',
      prevEl: '.js-swiper-product-carousel-container .js-carousel-indicator__arrow--prev',
      disabledClass: 'a-carousel-indicator__arrow--disabled'
    },
    scrollbar: {
      el: '.js-swiper-scrollbar',
      draggable: true
    },
    mousewheel: {
      forceToAxis: true
    },
    grabCursor: true,
    keyboard: {
      enabled: true
    },
    watchSlidesVisibility: true,
    on: {
      transitionEnd: function () {
        var currentSlide = this.slides[this.activeIndex] as HTMLElement | undefined;
        if (currentSlide && !currentSlide.contains(document.activeElement)) {
          currentSlide.focus();
        }
      },
      init: function () {
        var that = this;
        this.slides.each(function (el, i) {
          this.addEventListener("focusin", function (e) {
            if ((that.activeIndex != i) && !el.classList.contains('swiper-slide-visible')) {
              that.slideTo(i);
            }
          });
        });
      }
    }
  })
}
