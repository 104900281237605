import { Controller } from "@hotwired/stimulus";

export class LoadMoreController extends Controller {
  declare resultsTarget: HTMLElement;
  static targets = ["results"];

  public update(e: CustomEvent) {
    const doc = e.detail[0] as HTMLDocument;
    const response = e.detail[2] as XMLHttpRequest;

    const link = e.currentTarget as HTMLAnchorElement;

    const nextPage = response.getResponseHeader('x-next-page');
    if (nextPage) {
      link.href = link.href.replace(/page=\d+/, `page=${nextPage}`);
    } else {
      link.style.visibility = 'hidden';
    }

    const children = Array.from(doc.body.children) as HTMLElement[];
    children.forEach(c => c.classList.add("is-highlighted"));
    this.resultsTarget.append(...children);

  }
}