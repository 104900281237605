type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never;

export function debounce<T extends Function>(callback: T, wait: number = 200) {
  let timeout: number;

  return (...args: ArgumentTypes<T>) => {
    const context = this;
    clearTimeout(timeout);
    timeout = window.setTimeout(() => callback.apply(context, args), wait);
  };
}
