
import * as React from "react";
import { ChangeEvent, MouseEvent, useCallback } from "react";
import { ShoppingCart } from "../../api/cart";
import { Alias } from "../../api/graphql-relay";
import { formatCurrency } from "../../helpers";

type OrderDetail = Alias<ShoppingCart, "orderDetails">;
type UpdateQtyFn = (id: number, quantity: number) => void;

interface MiniCartCardProps {
  detail: OrderDetail;
  updateQty: UpdateQtyFn,
  remove: (id: number) => void
}

export function MiniCartCard(props: MiniCartCardProps) {
  const { detail, updateQty, remove } = props;
  const { name, photo, winery, url } = detail.product!;
  const [mainPhoto, ...srcset] = photo || [];

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    updateQty(detail.product!.id, Number.parseInt(e.currentTarget.value, 10));
  }, [detail, updateQty]);

  const onRemove = useCallback((e: MouseEvent) => {
    e.preventDefault();
    remove(detail.product!.id);
  }, [detail, remove]);

  return (
    <div className="m-mini-cart__item">
      <div className="m-mini-product-card ">
        <a className="m-mini-product-card__image-wrapper" href={url}>
          <img className="m-mini-product-card__image" src={mainPhoto} srcSet={srcset.join(", ")} alt="Product Card Image" />
        </a>
        <div className="m-mini-product-card__info">
          <div className="m-mini-product-card__header">
            <p className="m-mini-product-card__brand">{winery.name}</p>
            <h6 className="m-mini-product-card__name"><a href={url} className="a-product-name">{name}</a></h6>
            <div className="m-mini-product-card__price">{formatCurrency(detail.price, 0)}</div>
          </div>

          <div className="m-mini-product-card__footer">
            <div className="m-mini-product-card__cart-action">
              <div className="m-mini-product-card__input js-mini-product-card__input">
                {quantityField(detail, updateQty, onChange)}
                <a href="#" onClick={onRemove} className="remove-link">Remove</a>
              </div>
              <div
                className="m-mini-product-card__update m-mini-product-card__update--hidden js-mini-product-card__update">
                <button className="a-btn a-btn--text" type="button">Update</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


function quantityField(detail: OrderDetail, updateQty: UpdateQtyFn, onChange: (e: React.ChangeEvent) => void) {
  const { product, quantity } = detail;

  if (product && product.availableInZipCode) {
    return (
      <div className="m-qty-input">
        <span onClick={() => updateQty(product.id, quantity - 1)} className="icon-collapse a-icon_icon"></span>
        <input type="number" min="1" max="99" value={quantity} onChange={onChange} />
        <span onClick={() => updateQty(product.id, quantity + 1)} className="icon-expand a-icon_icon"></span>
      </div>
    );
  }

  return (<div>Unavailable in your zip code</div>)
}