import { GraphQLClient } from "graphql-request";
import { print } from "graphql/language/printer";
import gql from "graphql-tag";
import { ASTNode } from "graphql";
import { PageInfo } from "./graphql";

const BaseAPIPath = "/api/v2";

const pageInfoFragment: ASTNode = gql`
  fragment pageInfo on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }`;

interface IRelayResponse<T> {
  nodes: T[];
  pageInfo: PageInfo;
  totalCount?: number;
}

async function request<T, V = {}>(query: ASTNode, variables?: V, path: string = BaseAPIPath): Promise<T> {
  const client = new GraphQLClient(path, { credentials: "same-origin", cache: "no-cache" });
  return await client.request<T>(print(query), variables);
}

type OptionalPick<T, K extends keyof T> = { [P in K]?: T[P]; };

export { request, pageInfoFragment, IRelayResponse, OptionalPick };

type Unbox<T> = T extends Array<infer U> ? NonNullable<U> : NonNullable<T>;

type Alias1<T1> = Unbox<T1>;

type Alias2<
  T1,
  T2 extends keyof Alias1<T1> | "" = ""
  > = Unbox<T2 extends keyof Alias1<T1> ? Alias1<T1>[T2] : Alias1<T1>>;

type Alias3<
  T1,
  T2 extends keyof Alias1<T1> | "" = "",
  T3 extends keyof Alias2<T1, T2> | "" = ""
  > = Unbox<T3 extends keyof Alias2<T1, T2> ? Alias2<T1, T2>[T3] : Alias2<T1, T2>>;

type Alias4<
  T1,
  T2 extends keyof Alias1<T1> | "" = "",
  T3 extends keyof Alias2<T1, T2> | "" = "",
  T4 extends keyof Alias3<T1, T2, T3> | "" = ""
  > = Unbox<T4 extends keyof Alias3<T1, T2, T3> ? Alias3<T1, T2, T3>[T4] : Alias3<T1, T2, T3>>;

/**
 * Alias a type hierarchy.
 *
 *    type CartType = Alias<CartQuery, "cart">;
 *
 * Gets the type of the child type even if that type has no name.  Useful for getting the type
 * of generated types in GraphQL queries.
 *
 * If the resulting type is nullable or an array, that type is unboxed into its non nullable, non array type.
 *
 * For example:
 *    type CartLine = Alias<CartQuery, "cart", "orderLines">;
 *
 * This type will be the type of a non-nullable order line.
 */
export type Alias<
  T1,
  T2 extends keyof Alias1<T1> | "" = "",
  T3 extends keyof Alias2<T1, T2> | "" = "",
  T4 extends keyof Alias3<T1, T2, T3> | "" = "",
  T5 extends keyof Alias4<T1, T2, T3, T4> | "" = ""
  > = Unbox<T5 extends keyof Alias4<T1, T2, T3, T4> ? Alias4<T1, T2, T3, T4>[T5] : Alias4<T1, T2, T3, T4>>;