import { Controller } from "@hotwired/stimulus";

export class ClickToCopyController extends Controller {
  declare valueTarget: HTMLInputElement;

  static targets = ["value"];

  public copy(e: Event) {
    e.preventDefault();

    // If the input is disabled, we need to enable it temporarily
    const isDisabled = this.valueTarget.disabled;

    this.valueTarget.disabled = false;

    this.valueTarget.select();
    this.valueTarget.setSelectionRange(0, 99999);

    document.execCommand('copy');
    document.getSelection()?.removeAllRanges();

    this.valueTarget.disabled = isDisabled;

    alert("Copied");
  }
}