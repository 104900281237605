import * as React from "react";
import { formatCurrency } from "../../helpers";

interface MiniCartFooterProps {
  totalCost: number;
}

export function MiniCartFooter(props: MiniCartFooterProps) {
  return (
    <div className="m-mini-cart__actions">
      <a className="a-btn a-btn--primary" href="/checkout">Checkout - {formatCurrency(props.totalCost)}</a>
    </div>
  );
}
