
import * as React from "react";
import { useCallback, useState } from "react";
import { setZipCode, ShoppingCart } from "../../api/cart";
import { pluralize } from "../../helpers";

interface MiniCartHeaderProps {
  cart: ShoppingCart | null;
  setCart: (cart: ShoppingCart | null) => void;
  zipCode: string | undefined;
}

export function MiniCartHeader(props: MiniCartHeaderProps) {
  const { cart, setCart } = props;
  const [zip, setZip] = useState(props.zipCode || '');

  const callback = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();

    const response = await setZipCode(zip);

    if (response.successful) {
      console.log(response);
      setCart(response.result)
    } else {
      alert(response.errors?.[0].message);
      setZip("");
    }

  }, [zip, setZip, setZipCode]);

  return (
    <form className="m-mini-cart__heading" onSubmit={callback}>
      <h3 className="m-mini-cart__title">Cart - {pluralize(cart?.orderDetails.length ?? 0, "Item")}</h3>
      <div className="m-zip-input">
        <div className="m-zip-input__container">
          <div className="m-zip-input__input-container">
            <span className="a-icon m-zip-input__icon">
              <span className="icon-tooltip a-icon_icon"></span>
            </span>
            <input
              className="m-zip-input__input"
              placeholder="Zip Code"
              type="number"
              value={zip}
              onChange={e => setZip(e.currentTarget.value)} />
          </div>
          <button className="a-icon-text-btn  m-zip-input__button" type="submit">
            <span className="icon-arrow-right a-icon-text-btn__icon" aria-hidden="true"></span>
          </button>
        </div>
      </div>
    </form>
  );
}