import { useCallback } from "react";
import { useDebounce } from "../../hooks";
import { ShoppingCart, updateCartLine } from "../../api/cart";

type SetCartCallback = (cart: ShoppingCart | null) => void;

/**
 * Visually updates the quantities as you click, but only calls back to the
 * server after a debounce time
 */
export function useOptimisticUpdateOrder(cart: ShoppingCart | null, setCart: SetCartCallback) {

  // Actual debounced call to update the cart line
  const submitDetailUpdate = useDebounce(async (id: number, quantity: number) => {
    const response = await updateCartLine(id, quantity);

    if (response.successful) {
      setCart(response.result);
    }
  }, [setCart], 500);

  return useCallback((id: number, quantity: number) => {
    // Can't update a cart that doesn't exist
    if (!cart) { return; }

    // Don't allow the quantity to go below 1
    quantity = Math.max(quantity, 1);
    submitDetailUpdate(id, quantity);

    // Pull the order details and update the quantity
    const orderDetails = [...cart.orderDetails];
    const index = orderDetails.findIndex(d => d.product.id === id);

    // Make sure we found the line before doing anything
    if (index !== -1) {
      orderDetails[index] = { ...orderDetails[index], quantity };
      setCart({ ...cart, orderDetails });
    }

  }, [cart, setCart, submitDetailUpdate]);
}
