import { getLocalStorage, setLocalStorage } from './cookies';

const Over21Storage = 'isOver21';

export function checkAge() {
  if (getLocalStorage("isOver21") !== 'true') {
    toggleAgeGate()
  }
}

function toggleAgeGate() {
  const modal = document.querySelector('.m-modal');
  if (!modal) { return; }

  const modalContent = modal.querySelector('.js-modal-content');
  const template = document.querySelector('template');

  const ageGate = template?.content.querySelector('.m-age-gate');

  if (modal.classList.contains('m-modal--active')) {
    if (modalContent)
      modalContent.innerHTML = "";
  } else {
    if (ageGate)
      modalContent?.appendChild(ageGate)

    document.querySelector('.js-21-no')?.
      addEventListener('click', () => window.location.href = "https://www.google.com");

    document.querySelector('.js-21-yes')?.
      addEventListener('click', () => {
        setLocalStorage(Over21Storage, 'true')
        toggleAgeGate();
      });
  }
  modal.classList.toggle('m-modal--active')
}