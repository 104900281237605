import { Controller } from "@hotwired/stimulus";

export class SelectionUpdateController extends Controller {
  declare outputTarget: HTMLElement;
  declare optionKeyValue: string;

  static targets = ['output'];
  static values = { optionKey: String };

  public choose(e: Event) {
    const target = e.currentTarget as HTMLSelectElement;

    const option = target.options.item(target.options.selectedIndex);
    this.outputTarget.textContent = option?.dataset[this.optionKeyValue] || '';
  }
}