import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";

const ACTIVE_DRAWER_CLASS = 'm-filter-drawer--active';

export class FilterDrawerController extends Controller {
  declare sortSelectTarget: HTMLElement;
  static targets = ["sortSelect"];

  /**
   * Toggle the filter drawer on and off
   */
  public toggle() {
    // If we're closing the drawer, just set the filter
    if (this.element.classList.contains(ACTIVE_DRAWER_CLASS)) {
      this.filter();
    }

    this.element.classList.toggle(ACTIVE_DRAWER_CLASS);
  }

  public select(e: MouseEvent) {
    e.preventDefault();

    const target = e.currentTarget as HTMLElement;
    target.dataset['active'] = target.dataset['active'] === 'true' ? 'false' : 'true';
  }

  /**
   * Rebuild the search url by the active filters
   */
  public filter(e?: Event) {
    if (e) { e.preventDefault(); }

    const elements = Array.from(this.element.querySelectorAll<HTMLElement>("[data-active=true]"));
    let search = elements.map(e => `${e.dataset['param']}[]=${e.dataset['value']}`);

    if (this.sort) {
      search = [...search, `sort=${this.sort}`];
    }

    const url = new URL(window.location.href);
    url.search = encodeURI(search.join("&"));

    Turbo.visit(url.toString());
  }

  private get sort(): string | undefined {
    const result = this.sortSelectTarget.dataset['dropdownSelectedKeyValue'];

    if (result === "") { return undefined; }

    return result;
  }
}