import { Controller } from "@hotwired/stimulus";

export class ProductGalleryController extends Controller {
  declare thumbnailsTarget: HTMLElement;
  static targets = ["thumbnails"];

  public left() {
    this.selectedIndex = this.selectedIndex - 1;
  }

  public right() {
    this.selectedIndex = this.selectedIndex + 1;
  }

  public select() {
    const html = this.selected?.dataset['value'];

    if (html) {
      this.image.outerHTML = html;

      this.inputs.forEach(i => {
        if (i.checked) {
          i.parentElement?.classList.add("selected")
        } else {
          i.parentElement?.classList.remove("selected")
        }
      });
    }
  }

  private get inputs() {
    return Array.from(this.thumbnailsTarget.querySelectorAll<HTMLInputElement>("input[type=radio]"));
  }

  private get selectedIndex(): number {
    return this.inputs.findIndex(i => i.checked);
  }

  private set selectedIndex(newIndex: number) {
    const index = Math.max(Math.min(newIndex, this.count - 1), 0);
    const input = this.inputs[index];
    input.checked = true;

    this.select();
  }

  private get selected(): HTMLInputElement | null {
    return this.thumbnailsTarget.querySelector<HTMLInputElement>("input[type=radio]:checked");
  }

  private get count(): number {
    return this.thumbnailsTarget.childElementCount;
  }

  private get image(): HTMLImageElement {
    return this.element.querySelector(".m-product-gallery__media") as HTMLImageElement;
  }
}