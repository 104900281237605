import { Controller } from "@hotwired/stimulus";

type AddressField = "first_name" | "last_name" | "address1" | "address2" | "city" | "state" | "zipcode" | "country";

export class CheckoutFormController extends Controller {
  declare formTarget: HTMLFormElement;
  declare cardErrorsTarget: HTMLDivElement;
  declare nextTarget: HTMLButtonElement;
  declare stripeIdValue: string;
  declare hasStripeIdValue: boolean;
  declare addressIdValue: number;

  static targets = ["form", "cardErrors", "next"];
  static values = { stripeId: String, addressId: Number };

  connect() {
    this.addressIdValueChanged();

    if (this.hasStripeIdValue) {
      this.initializeStripe();
    }
  }

  /**
   * Occurs when a saved address is selected
   */
  public selected(event: Event) {
    const el = event.currentTarget as HTMLInputElement;
    this.addressIdValue = Number.parseInt(el.value, 10);
  }

  public addressIdValueChanged() {
    this.formTarget.style.display = this.addressIdValue ? 'none' : 'inherit';
    this.nextTarget.style.display = this.addressIdValue ? 'inherit' : 'none';
  }

  private async initializeStripe() {
    const stripe = Stripe(this.stripeIdValue);

    const elements = stripe.elements();
    const card = elements.create('card', { hidePostalCode: true });

    card.mount('#card-element');

    card.on('change', e => {
      this.cardErrorsTarget.textContent = e?.error?.message || '';
    });

    this.formTarget.addEventListener('submit', async e => {
      this.formDisabled = true;
      e.preventDefault();

      const result = await stripe.createToken(card, {
        name: `${this.getField('first_name')} ${this.getField('last_name')}`,
        address_line1: this.getField("address1"),
        address_line2: this.getField('address2'),
        address_city: this.getField('city'),
        address_state: this.getField('state'),
        address_zip: this.getField('zipcode'),
        address_country: this.getField('country')
      });

      if (result.error) {
        // Inform the user if there was an error.
        this.cardErrorsTarget.textContent = result.error.message || '';
        this.formDisabled = false;
      } else {
        // Send the token to your server.
        this.stripeTokenHandler(result.token!);
      }
    });
  }

  private stripeTokenHandler(token: stripe.Token) {
    // Insert the token ID into the form so it gets submitted to the server
    var hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', 'stripeToken');
    hiddenInput.setAttribute('value', token.id);
    this.formTarget.appendChild(hiddenInput);

    // Submit the form
    this.formTarget.submit();
  }

  private getField(name: AddressField): string | undefined {
    const fields = ["order", "wine_club_subscription"].
      map(f => `input#${f}_billing_address_attributes_${name}`).
      join(',');

    const field = this.formTarget.querySelector<HTMLInputElement>(fields);

    return field?.value;
  }

  private set formDisabled(disabled: boolean) {
    const button = this.formTarget.querySelector('input[type=submit]') as HTMLInputElement;

    if (disabled) {
      button.disabled = true;
      button.value = 'Please wait...';
    } else {
      button.disabled = false;
      button.value = 'Place your order';
    }
  }
}