import { Controller } from "@hotwired/stimulus";

const OpenClassName = "m-dropdown--is-open";

export class DropdownController extends Controller {
  declare inputTarget: HTMLInputElement;
  declare hasInputTarget: boolean;
  declare buttonTarget: HTMLButtonElement;
  declare selectedKeyValue: string;
  static targets = ["button", "input"];
  static values = { selectedKey: String };

  toggle() {
    this.element.classList.toggle(OpenClassName);
  }

  public keydown(e: KeyboardEvent) {
    if (e.key === 'Escape') { this.close(); }
  }

  public select(e: MouseEvent) {
    e.preventDefault();

    const value = (e.target as HTMLElement).dataset["value"]!;
    const key = (e.target as HTMLElement).dataset["key"] || value;

    this.buttonTarget.innerText = value;
    this.selectedKeyValue = key;
  }

  public selectedKeyValueChanged() {
    this.close();

    if (this.hasInputTarget) {
      this.inputTarget.value = this.selectedKeyValue;
    }
  }

  public close() { this.element.classList.remove(OpenClassName); }
}