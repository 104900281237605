import { Controller } from "@hotwired/stimulus";

const OpenClassName = "m-accordion__content--open";

export class AccordionController extends Controller {
  declare contentTarget: HTMLElement;
  static targets = ["content"];

  public toggle(e: Event) {
    const target = e.currentTarget as HTMLElement;

    target.classList.toggle("m-accordion__title--open");

    if (this.contentTarget.classList.contains(OpenClassName)) {
      this.contentTarget.classList.remove(OpenClassName);
      this.contentTarget.style.maxHeight = "0px";
    } else {
      this.contentTarget.classList.add(OpenClassName);
      this.contentTarget.style.maxHeight = this.contentTarget.scrollHeight + "px";
    }
  }
}