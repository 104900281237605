import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export function scrollEvents() {
  gsap.registerPlugin(ScrollTrigger);
  gsap.utils.toArray('.js-anim-scroll').forEach((element: HTMLElement) => {
    gsap.from(element, {
      y: 100,
      opacity: 0,
      duration: .5,
      scrollTrigger: {
        trigger: element,
        start: "top bottom",
        end: "top center",
        scrub: 1,
      }
    })
  });

  if (document.querySelector('.m-flavor-chart__dot')) {
    gsap.from('.m-flavor-chart__dot', {
      left: 0,
      duration: .3,
      stagger: 0.1,
      scrollTrigger: {
        trigger: '.m-flavor-chart__container',
        toggleActions: 'play play none reverse'
      }
    })
  }

  const winestyrWay = document.querySelector('.c-winestyr-way__graphic');
  if (winestyrWay) {
    const l = document.querySelector<SVGPathElement>('#Path_152')?.getTotalLength();

    if (!l) { return; }

    const tl = gsap.timeline({ paused: true })
    gsap.set('#Path_152', {
      strokeDasharray: l
    })
    gsap.set('#Group_299,#Group_300', {
      transformOrigin: "50% 50%"
    })
    tl.fromTo('#Wholesale_Warehouse,#Retail_Store', {
      fill: "#000000",
      duration: .5
    }, {
      opacity: .3
    }
      , "<")
    tl.fromTo('#Group_299 *,#Group_300 *', {
      stroke: "#000000",
      duration: .5
    }, {
      opacity: .3
    }
      , "<")

    tl.to('#Group_299,#Group_300', {
      scale: .9,
      duration: .5
    }, "<")


    tl.from('#Path_152', {
      attr: { "stroke-dashoffset": -l },
      duration: 1,
    }, ">")
    tl.from('#Path_151, .c-winestyr-way__svg-mob #Path_151-3', {
      opacity: 0,
      duration: .5
    }, ">")

    ScrollTrigger.create({
      trigger: '.c-winestyr-way__graphic',
      animation: tl,
      start: 'top center',
      end: 'top center',
      toggleActions: 'play none none none',

    })
  }

  gsap.utils.toArray('.js-anim-stagger').forEach((section: HTMLElement) => {
    const items = section.querySelectorAll<HTMLElement>('.js-anim-stagger-item');
    const isMobile = window.innerWidth <= 1024;

    if (isMobile) {
      items.forEach(element => {
        gsap.from(element, {
          y: 100,
          opacity: 0,
          duration: 0.5,
          scrollTrigger: {
            trigger: element,
            start: "top bottom",
            end: "top center",
            scrub: 1,
          }
        })
      });
    } else {
      gsap.from(items, {
        y: 100,
        opacity: 0,
        stagger: 0.2,
        duration: 0.5,
        scrollTrigger: {
          trigger: section,
          start: "top bottom",
          end: "top center",
          scrub: 1,
        },
      })
    }
  });

  /*
  Force update the scroll trigger.

  Due to turbo placing the scroll position anywhere on the page, we have
  to force update the scroll events to allow the elements to show on the
  screen based on where we currently are.
  */

  ScrollTrigger.refresh(true);
}