import { useCallback, useState } from "react";

export interface UseVisibilityOptions {
  onShow?: () => void;
  onHide?: () => void;
  initialState?: boolean
}

export function useVisibility(options?: UseVisibilityOptions): [boolean, () => void, () => void] {
  const [visible, setVisible] = useState(options?.initialState || false);

  const show = useCallback(() => {
    setVisible(true);
    options?.onShow?.();

  }, [setVisible, options]);
  const hide = useCallback(() => {
    setVisible(false)
    options?.onHide?.();
  }, [setVisible, options]);

  return [visible, show, hide];
}