export function $(selector: string, parent?: HTMLElement) {
  return (parent || document).querySelector<HTMLElement>(selector);
};

export function $$(selector: string, parent?: HTMLElement) {
  return Array.from((parent || document).querySelectorAll<HTMLElement>(selector));
};


/**
 * Converts a string into title case
 *
 * @param {string} text - Message to title case
 * @returns {string} Title case version of string
 */
export function titlecase(text: string | null | undefined): string {
  // Return the text as is if it's blank
  if (!text) { return ""; }

  return text
    .split(" ")
    .map(word => word.charAt(0).toLocaleUpperCase() + word.slice(1))
    .join(" ");
}

/**
 *
 * @param {number} value Value to format to currency
 * @param {number} precision Number of decimal places
 *
 * @returns Value as a USD formatted string
 */
export function formatCurrency(value: number, precision: number = 2): string {
  return `$${value.toFixed(precision)}`;
}

export function pluralize(quantity: number, word: string, pluralForm?: string): string {
  if (quantity === 1) {
    return `${quantity} ${word}`;
  }

  return `${quantity} ${pluralForm || word + 's'}`;
}