import { Controller } from "@hotwired/stimulus";

type AddressField = "first_name" | "last_name" | "address1" | "address2" | "city" | "state" | "zipcode" | "country";

export class StripeFormController extends Controller {
  declare cardErrorsTarget: HTMLElement;
  declare hasStripeIdValue: boolean;
  declare stripeIdValue: string;
  static targets = ["cardErrors"];
  static values = { stripeId: String }

  connect() {
    this.initializeStripe();
  }

  private async initializeStripe() {
    if (!this.hasStripeIdValue) {
      return;
    }

    const stripe = Stripe(this.stripeIdValue);

    const elements = stripe.elements();
    const card = elements.create('card', { hidePostalCode: true });

    card.mount('#card-element');

    card.on('change', e => {
      this.cardErrorsTarget.textContent = e?.error?.message || '';
    });

    this.element.addEventListener('submit', async e => {
      e.preventDefault();

      try {
        const result = await stripe.createToken(card, {
          name: `${this.getField('first_name')} ${this.getField('last_name')}`,
          address_line1: this.getField("address1"),
          address_line2: this.getField('address2'),
          address_city: this.getField('city'),
          address_state: this.getField('state'),
          address_zip: this.getField('zipcode'),
          address_country: this.getField('country')
        });

        if (result.error) {
          // Inform the user if there was an error.
          this.cardErrorsTarget.textContent = result.error.message
            || 'A fatal error occurred while trying to process payment.  Please contact support@winestyr.com';
          this.reenable();
        } else {
          this.stripeTokenHandler(result.token!);
        }
      } catch (err) {
        alert(`A fatal error occurred while trying to process payment.  Please contact support@winestyr.com: ${err}`)
        this.reenable();
      }
    });
  }

  private stripeTokenHandler(token: stripe.Token) {
    // Insert the token ID into the form so it gets submitted to the server
    const hiddenInput = document.createElement('input');

    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', 'stripeToken');
    hiddenInput.setAttribute('value', token.id);
    this.element.appendChild(hiddenInput);

    // Submit the form
    (this.element as HTMLFormElement).submit();
  }

  private reenable() {
    this.submitButton.disabled = false;
  }

  private get submitButton() {
    return this.element.querySelector("input[type=submit]")! as HTMLInputElement;
  }

  private getField(name: AddressField): string | undefined {
    const field = this.element.querySelector<HTMLInputElement>(`input#address_${name}`);
    return field?.value;
  }
}