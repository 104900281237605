import { useEffect } from "react";

export function useOnKeyDown(targetKey: string, callback: (key: string) => void) {
  useEffect(() => {
    const downHandler = ({ key }: KeyboardEvent) => {
      if (key == targetKey) { callback(key); }
    };

    window.addEventListener('keydown', downHandler);
    return () => { window.removeEventListener('keydown', downHandler); };
  }, [targetKey, callback]);
}