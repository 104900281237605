import { Controller } from "@hotwired/stimulus";

const CLASSNAME = 'm-mini-nav--active';

export class MiniNavController extends Controller {
  declare panelTarget: HTMLElement;
  declare overlayTarget: HTMLElement;
  static targets = ["panel", "overlay"];

  public connect() {
    this.onScroll = this.onScroll.bind(this);
  }

  public toggle() {
    this.element.classList.toggle(CLASSNAME);

    // Sync up the scrolling if the nav is visible
    if (this.element.classList.contains(CLASSNAME)) {
      window.addEventListener("scroll", this.onScroll);
      this.onScroll();
    } else {
      window.removeEventListener("scroll", this.onScroll);
    }
  }

  public onScroll() {
    const header = document.querySelector<HTMLElement>('.c-header');
    const headerBottom = header?.getBoundingClientRect().bottom || 0;

    const top = `${headerBottom}px`;

    this.panelTarget.style.top = top;
    this.overlayTarget.style.top = top;
  }
}