import { Controller } from "@hotwired/stimulus";
import Swiper, { SwiperOptions } from "swiper";

export class CarouselController extends Controller {
  private swiper: Swiper;

  declare hasThumbnailsTarget: boolean;
  declare thumbnailsTarget: HTMLElement;

  static targets = ["thumbnails"];

  connect() {
    this.swiper = new Swiper(this.element as HTMLElement, this.options);

    if (this.hasThumbnailsTarget) {
      this.swiper.on('slideChange', this.onSlideChange.bind(this));
    }
  }

  disconnect() {
    this.swiper.destroy()
  }

  private onSlideChange() {
    const thumbs = this.thumbnailsTarget.querySelectorAll<HTMLInputElement>(`input[type=radio]`);

    if (this.currentThumbnail) {
      this.currentThumbnail.checked = true;
    }
  }

  public select(e: Event) {
    const target = e.currentTarget as HTMLInputElement;
    const index = Number.parseInt(target.dataset["index"] || '0', 10);

    this.swiper.slideTo(index);
  }

  private get options(): SwiperOptions {
    let options: SwiperOptions = {
      grabCursor: true,
      centeredSlides: true
    };

    return options;
  }

  private get currentThumbnail() {
    const thumbs = this.thumbnailsTarget.querySelectorAll<HTMLInputElement>(`input[type=radio]`);

    return thumbs[this.swiper.realIndex];
  }
}