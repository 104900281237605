import { useEffect, useRef } from "react";

type HandlerFn<T> = (e: T) => void;

export function useEventListener<T extends Event = Event>(eventName: string, handler: HandlerFn<T>, element: EventTarget = window) {
  // Create a ref that stores handler
  const savedHandler = useRef<HandlerFn<T>>();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => { savedHandler.current = handler; }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      const isSupported = element.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = (event: T) => savedHandler.current?.(event);

      // Add event listener
      element.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => { element.removeEventListener(eventName, eventListener); };
    },
    [eventName, element]
  );

}