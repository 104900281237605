import { Controller } from "@hotwired/stimulus";

export class CharacterCountController extends Controller {
  declare charactersTarget: HTMLElement;

  static targets = ["characters"];

  public change(e: KeyboardEvent) {
    const target = e.currentTarget as HTMLInputElement | HTMLTextAreaElement;
    this.charactersTarget.innerText = target.value.length.toString();
  }
}