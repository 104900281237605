import Swiper from 'swiper';

export function StartQuoteCarousel() {
  new Swiper('.js-swiper-quote-carousel-container', {
    slidesPerView: 'auto',
    threshold: 10,
    navigation: {
      nextEl: '.c-quote-carousel__carousel .js-carousel-indicator__arrow--next',
      prevEl: '.c-quote-carousel__carousel .js-carousel-indicator__arrow--prev',
      disabledClass: 'a-carousel-indicator__arrow--disabled'
    },
    pagination: {
      el: '.swiper-pagination',
    },
    mousewheel: {
      forceToAxis: true
    },
    grabCursor: true,
    keyboard: {
      enabled: true
    },
    watchSlidesVisibility: true,
    on: {
      transitionEnd: function (this: Swiper) {
        var currentSlide = this.slides[this.activeIndex] as HTMLElement | undefined;
        if (!currentSlide?.contains(document.activeElement)) {
          currentSlide?.focus();
        }
      },
      init: function (this: Swiper) {
        var that = this;
        this.slides.each(function (el: HTMLElement, i: number) {
          this.addEventListener("focusin", function () {
            if ((that.activeIndex != i) && !el.classList.contains('swiper-slide-visible')) {
              that.slideTo(i);
            }
          });
        });
      }
    }
  })

}
