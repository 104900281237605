export function setLocalStorage(name: string, value: string) {
  localStorage.setItem(name, value);
}

export function getLocalStorage(name: string) {
  return localStorage.getItem(name);
}

export function checkAllowCookies() {
  return getLocalStorage("allowCookies") == "true";
}

export function showCookieBanner() {
  // Skip if user already accepted
  if (checkAllowCookies()) {
    return;
  }

  const template = document.querySelector("template");
  const cookieBanner = template?.content.querySelector(".m-cookie-banner");

  if (!cookieBanner) { return }

  document.body.appendChild(cookieBanner);

  const allowButton = cookieBanner.querySelector(".js-allow-cookies");

  allowButton?.addEventListener("click", () => {
    setLocalStorage("allowCookies", "true");
    document.body.removeChild(cookieBanner);
  });
}