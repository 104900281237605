import gql from "graphql-tag";
import {
  CurrentCartQuery,
  UpdateCartLineMutation,
  UpdateCartLineMutationVariables,
  Error as GraphQLError,
  MutationAddCodeToCartArgs,
  AddCodeToCartMutation,
  RemoveDiscountCodeMutation,
  SetZipCodeMutation,
  SetZipCodeMutationVariables
} from "./graphql";

import { Alias, request } from "./graphql-relay";


/**
 * Cart has been updated event
 * 
 * Holds the current cart after it has been updated. This allows the site to
 * reload the cart on the mini cart without having another round trip to the
 * server.
 */
export class CartUpdatedEvent extends CustomEvent<ShoppingCart> {
  static Name = 'winestyr:cart:updated';

  constructor(cart: ShoppingCart) {
    super(CartUpdatedEvent.Name, { detail: cart });
  }
}

export type ShoppingCart = Alias<CurrentCartQuery, "currentCart">;
export type Result<T> = {
  /**
   * Determines if the mutation was successful or not
   */
  successful: boolean;

  /**
   * The result of the mutation if successful
   */
  result: T | null;

  /**
   * A list of errors if the mutation failed
   */
  errors: GraphQLError[] | null;
};

const addressFragment = gql`
  fragment addressFields on Address {
    firstName
    lastName
    companyName
    address1
    address2
    city
    state
    zipcode
    country
    birthdate
  }
`;

const cartFragment = gql`
  fragment cartFields on Order {
    state
    orderDetails {
      price
      quantity
      product {
        photo
        id
        name
        slug
        url
        availableInZipCode
        winery {
          name
        }
      }
    }
    
    subtotal
    shippingCost
    discountCode {
      code
      amount
    }
    appliedGiftCards {
      code
      amountApplied
    }
    tax
    totalCost
    
    billingAddress { ...addressFields }
    shippingAddress { ...addressFields }
  }
  ${addressFragment}
`;

const currentCart = gql`
  query currentCart {
    currentCart { ... cartFields }
  }
  ${cartFragment}
`;

const updateCartLineMutation = gql`
  mutation updateCartLine($cartLine: UpdateCartLineAttributes!) {
    updateCartLine(cartLine: $cartLine) {
      successful
      errors {
        field
        message
      }
      result { ... cartFields }
    }
  }
  ${cartFragment}
`;

const addCodeToCartMutation = gql`
  mutation addCodeToCart($code: String!) {
    addCodeToCart(code: $code) {
      successful
      errors {
        field
        message
      }
      result { ... cartFields }
    }
  }
  ${cartFragment}
`;

const removeDiscountCodeMutation = gql`
  mutation removeDiscountCode {
    removeDiscountCode {
      successful
      errors {
        field
        message
      }
      result { ... cartFields }
    }
  }
  ${cartFragment}
`;

const setZipCodeMutation = gql`
  mutation SetZipCode($zipCode: String!) {
    setZipCode(zipCode: $zipCode) {
      successful
      errors {
        field
        message
      }
      result { ... cartFields }
    }
  }

  ${cartFragment}

`;
// Returns the current cart for the user or NULL if no cart has been created yet
export async function getCurrentCart(): Promise<ShoppingCart | null> {
  const result = await request<CurrentCartQuery>(currentCart);
  return result.currentCart;
}

// Adds or updates a cart line
export async function updateCartLine(productId: number, quantity: number): Promise<Result<ShoppingCart>> {
  const result = await request<UpdateCartLineMutation, UpdateCartLineMutationVariables>(
    updateCartLineMutation, { cartLine: { productId, quantity } }
  );

  return result.updateCartLine;
}

// Removes a product from the cart
export async function deleteCartLine(productId: number): Promise<Result<ShoppingCart>> {
  return updateCartLine(productId, 0);
}

export async function addCode(code: string): Promise<Result<ShoppingCart>> {
  const result = await request<AddCodeToCartMutation, MutationAddCodeToCartArgs>(
    addCodeToCartMutation, { code }
  );

  return result.addCodeToCart;
}

export async function removeDiscountCode(): Promise<Result<ShoppingCart>> {
  const result = await request<RemoveDiscountCodeMutation>(removeDiscountCodeMutation);
  return result.removeDiscountCode;
}

export async function setZipCode(zipCode: string): Promise<Result<ShoppingCart>> {
  const result = await request<SetZipCodeMutation, SetZipCodeMutationVariables>(setZipCodeMutation, { zipCode });

  return result.setZipCode;
}