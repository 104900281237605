import * as React from "react";
import { useCallback, useState } from "react";
import { useOnKeyDown } from "../../hooks";

import { CartUpdatedEvent, getCurrentCart, ShoppingCart, updateCartLine } from "../../api/cart";

import { MiniCartCard } from "./mini-cart-card";
import { MiniCartFooter } from "./mini-cart-footer";
import { MiniCartHeader } from "./mini-cart-header";
import { useEventListener } from "../../hooks/use-event-listener";
import { useOptimisticUpdateOrder } from "./use-optimistic-update-order";

interface MiniCartProps {
  zipCode: string | undefined;
  quantity: string | undefined;
}

function useCartUpdatedEvent(setCart: (cart: ShoppingCart | null) => void, setVisible: (v: boolean) => void) {
  const callback = useCallback((e: CartUpdatedEvent) => {
    setCart(e.detail);
    setVisible(true);
  }, [setCart, setVisible]);

  useEventListener<CartUpdatedEvent>(CartUpdatedEvent.Name, callback);
}

export function MiniCart(props: MiniCartProps) {
  const [cart, setCart] = useState<ShoppingCart | null>(null);

  const [visible, setVisible] = useState<boolean>(false);
  const show = useCallback(() => setVisible(true), [setVisible]);
  const hide = useCallback(() => setVisible(false), [setVisible]);

  useCartUpdatedEvent(setCart, setVisible);

  const loadAndShowCart = useCallback(async () => {
    if (!cart) {
      setCart(await getCurrentCart());
    }

    show();
  }, [cart, setCart, show]);

  const updateQuantity = useOptimisticUpdateOrder(cart, setCart);
  const remove = useCallback(async (id: number) => {
    const response = await updateCartLine(id, 0);

    if (response.successful) {
      setCart(response.result);
    }
  }, [setCart]);

  const cartQuantity = cart?.orderDetails.length ?? props.quantity;

  // Hide if the use presses escape
  useOnKeyDown("Escape", hide);

  return (
    <div className={`m-mini-cart js-mini-cart ${visible ? 'm-mini-cart--active' : ''} `}>
      <button onClick={loadAndShowCart} className="m-mini-cart__btn">
        <span className="m-mini-cart__text"></span>
        <span className="m-mini-cart__counter" role="text" >{cartQuantity || ''}</span>
      </button>
      <div onClick={hide} className="m-mini-cart__overlay"></div>
      <div className="m-mini-cart__panel">
        <div onClick={hide} className="m-mini-cart__close-btn">
          <button className="a-icon-text-btn a-icon-text-btn--icon-only">
            <span className="icon-close a-icon-text-btn__icon" aria-hidden="true"></span>
            <span className="a-icon-text-btn__label">Close</span>
          </button>
        </div>
        <div className="m-mini-cart__content">
          <MiniCartHeader setCart={setCart} cart={cart} zipCode={props.zipCode} />
          <div className="m-mini-cart__list">{
            cart?.orderDetails.map(
              (detail, key) => <MiniCartCard key={key} updateQty={updateQuantity} remove={remove} detail={detail} />
            )
          }</div>
          <MiniCartFooter totalCost={cart?.totalCost ?? 0} />
        </div>
      </div>
    </div>
  );
}
