import { Controller } from "@hotwired/stimulus";
import { CartUpdatedEvent, updateCartLine } from "../api/cart";

export class AddToCartController extends Controller {
  declare valueTarget: HTMLInputElement;
  declare productIdValue: Number;
  static targets = ["value"];
  static values = { productId: Number }

  public increment() {
    this.valueTarget.value = Math.max(this.quantity + 1, 1).toString();
  }

  public decrement() {
    this.valueTarget.value = Math.max(this.quantity - 1, 1).toString();
  }

  public async submit() {
    if (!this.productIdValue) {
      console.log(`Invalid product: ${this.productIdValue}`);
      return;
    }

    const response = await updateCartLine(this.productIdValue.valueOf(), this.quantity);

    if (response.successful && response.result) {
      window.dispatchEvent(new CartUpdatedEvent(response.result));
    }
  }

  private get quantity(): number {
    return Number.parseInt(this.valueTarget.value || '', 10) || 1;
  }
}